
.dm-images-ctn {
    background-image: url('../../assets/img/header/liston-azul.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;
    min-height: 458px;
  }
  
  .dm-images-ctn-hero {
    background-image: url('../../assets/img/diaMuertos/6.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 92px 0;
    min-height: 318px;
   
  }
  
  .dm-images-ctn-hero-description {
    margin-left: 42px;
  }
  
  .dm-images-img-experience-hero {
    width: 40vw;
  }
  
  .dm-images-title {
    text-align: left;
    font-size: var(--txt-title-xl);
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 1px;
    margin-top: 42px ;
    margin-left: 50px;
    color: var(--white);
  }
  
  .dm-images-ctn-img {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    padding: 32px;
  }
  
  .dm-images-ctn-img-experience {
    flex: 1 0 30%;
    position: relative;
  }
  
  .dm-images-img-experience {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  
  .dm-images-ctn-img-experience:hover .dm-images-ctn-img-experience-description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dm-images-ctn-img-experience-description {
    display: none;
  }
  
  .dm-images-img-experience-description {
    text-align: center;
    font-size: var(--txt-h5-xl);
    font-weight: 700;
    color: #fff;
  }
  
  
   
  
  