.calendario2-eventos{
    display: flex;
    flex-direction: column;
    align-items:center;
}
.calendare-known-subtitle p{
    text-align: justify;
    width: 80%;
    margin:0px auto;
    color: #000;
}
.muertos-content{
    width: 100%;
}
.muertos-imagen1{
    grid-area: imagen1;
}
.muertos-imagen2{
    grid-area: imagen2;
}
.muertos-imagen3{
    grid-area: imagen3;
}
.muertos-imagen4{
    grid-area: imagen4;
}
.muertos-row{
    padding: 10px;
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 250px 250px 250px;
    grid-template-areas:
    "imagen1 imagen2"
    "imagen1 imagen3"
    "imagen4 imagen4";
}

.muertos-row img{ 
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.dm-images-ctn-hero{
    background-size: cover;
}