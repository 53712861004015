.footer-ctn {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.footer-ctn-contact {
  padding: 42px;
  background: var(--black);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Alinea al tope */
}

.footer-contact-logo {
  width: 260px;
  height: 80px;
  aspect-ratio: 19/6;
  object-fit: contain;
}

.footer-ctn-contact-section {
  flex: 1 0 100%; /* Full width for mobile */
  margin: 0 auto 42px 0;
  align-items: flex-start; /* Alinea al tope */
}

.footer-ctn-contact-section.logo-container {
  align-self: center; /* Centra el contenedor del logo */
}

.footer-ctn-contact-section > a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.footer-ctn-contact-section > div > div {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alinea al tope */
}

.footer-contact-section-text {
  color: #fff;
  font-size: var(--txt-p-xl);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-contact-section-title {
  color: #fff;
  font-size: var(--txt-h5-xl);
  font-weight: 700;
  text-align: center;
  margin: 24px auto;
}

.footer-contact-section-ul {
  list-style: none;
  padding: 0;
}

.footer-contact-section-ul li {
  margin-bottom: 12px;
}

.footer-ctn-contact-legal {
  background: black;
  display: block;
  height: 100%;
  padding: 64px 0;
}

.footer-ctn-contact-legal-links {
  display: block;
}

.footer-ctn-contact-legal-links p {
  text-align: center;
  font-size: var(--txt-p-md);
  margin: 0 auto;
  margin-top: 32px;
}

.footer-contact-legal-link {
  font-size: var(--txt-h5-md);
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
}

.footer-contact-rss {
  height: 36px;
  width: 36px;
}

@media screen and (min-width: 744px) and (max-width: 1024px) {
  .footer-ctn-contact-section {
    flex: 1 0 45%; /* 2 columns for iPad Mini */
    margin: 0 auto 42px 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1352px) {
  .footer-ctn-contact {
    flex-wrap: nowrap;
  }

  .footer-contact-logo {
    width: 220px;
    height: 60px;
  }

  .footer-ctn-contact-section {
    flex: 1 0 22%; /* 4 columns */
    margin: 0;
  }

  .footer-ctn-contact-legal {
    height: 120px;
    padding-top: 32px;
  }

  .footer-ctn-contact-legal-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1353px) {
  .footer-ctn-contact-section {
    flex: 1 0 22%; /* 4 columns */
  }
}