.map-interactive-ctn {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0 0 0 0;
}

.map-interactive-title {
	font-size: var(--txt-title-sm);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  color: #FFC000!important;
	padding: 32px;
	margin: 0;
	color: var(--white);
	text-align: center;
}

.leaflet-container {
	width: 100%;
	height: 970px;
}

.map-interactive-text {
	font-size: 16px;
	margin: 0;
}

.leaflet-container {
	background: #2d2d2d; /* Fondo del mapa */
  }
  
  
  
  
  
  .leaflet-popup {
	background-color: #444; /* Color de fondo de los popups */
	color: #000!important; /* Color del texto */
  }