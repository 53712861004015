.videos-tri-traveler-ctn {
  	box-sizing: border-box;
	height: 100%;
	width: 100%;
	margin-bottom: 120px;
}

.videos-tri-traveler-title {
	text-align: center;
	font-size: var(--txt-title-sm);
	font-family: 'Bebas Neue', sans-serif;
  	letter-spacing: 1px;
	color: var(--green);
	margin: 0;
}

.videos-tri-traveler-subtitle {
	text-align: center;
	font-size: var(--txt-h5-sm);
	margin: 0;
}

.videos-tri-traveler-ctn-videos {
	display: block;
	margin-top: 64px;
	height: 100%;
	width: 100%;
	padding: 0 32px;
	box-sizing: border-box;
}

.videos-tri-traveler-ctn-video-principal {
	width: 100%;
	height: 100%;
	aspect-ratio: 4/3;
	margin-bottom: 32px;
}

.videos-tri-traveler-ctn-video-list {
	display: block;
	flex: 1 0 100%;
	margin: 0;
	overflow-y: scroll;
	height: 100%;
	padding: 12px;
	border: 1px solid var(--gray);
	border-radius: 12px;
}

.videos-tri-traveler-ctn-video {
	position: relative;
}

.videos-tri-traveler-ctn-video-btn {
	height: 100%;
	width: 100%;
	border: none;
	background: transparent;
	cursor: pointer;
	position: absolute;
}

.videos-tri-traveler-ctn-video-text {
	height: 100%;
	width: 100%;
	position: absolute;
}

.videos-tri-traveler-video {
	height: 100%;
	width: 100%;
	aspect-ratio: 4/3;
	border-radius: 12px;
	padding-bottom: 72px;
}

.videos-tri-traveler-description {
	text-align: left;
	font-size: var(--txt-h5-sm);
	font-family: 'Bebas Neue', sans-serif;
  	letter-spacing: 1px;
	bottom: 0;
	left: 5px;
	position: absolute;
}

.videos-tri-traveler-video-principal {
	border-radius: 12px;
	height: 100%;
	width: 100%;
}

.videos-tri-traveler-principal-description {
	font-size: var(--txt-h5-sm);
	font-weight: 700;
	font-family: 'Bebas Neue', sans-serif;
  	letter-spacing: 1px;
}

@media screen and (min-width: 744px) {
	.videos-tri-traveler-title {
		font-size: var(--txt-title-md);
	}

	.videos-tri-traveler-subtitle {
		font-size: var(--txt-h5-md);
	}

	.videos-tri-traveler-description {
		font-size: var(--txt-h5-xl);
	}
}

@media screen and (min-width: 1353px) {
	.videos-tri-traveler-ctn {
		box-sizing: border-box;
		height: 100%;
		width: 100%;
		margin-bottom: 120px;
	}

	.videos-tri-traveler-title {
		text-align: center;
		font-size: var(--txt-title-xl);
		font-family: 'Bebas Neue', sans-serif;
	letter-spacing: 1px;
		color: var(--green);
		margin: 0;
	}

	.videos-tri-traveler-subtitle {
		text-align: center;
		font-size: var(--txt-h5-xl);
		margin: 0;
	}

	.videos-tri-traveler-ctn-videos {
		display: flex;
		gap: 32px;
		margin-top: 64px;
		height: 650px;
	}

	.videos-tri-traveler-ctn-video-principal {
		flex: 1 0 70%;
		padding: 0 32px 32px 32px;
	}

	.videos-tri-traveler-ctn-video-list {
		display: block;
		flex: 1 0 20%;
		margin: 0 32px 0 0;
		overflow-y: scroll;
		height: 100%;
		padding: 12px;
		border: 1px solid var(--gray);
		border-radius: 12px;
	}

	.videos-tri-traveler-ctn-video {
		position: relative;
	}

	.videos-tri-traveler-ctn-video-btn {
		height: 100%;
		width: 100%;
		border: none;
		background: transparent;
		cursor: pointer;
		position: absolute;
	}

	.videos-tri-traveler-ctn-video-text {
		height: 100%;
		width: 100%;
		position: absolute;
	}

	.videos-tri-traveler-video {
		height: 100%;
		width: 100%;
		aspect-ratio: 4/3;
		border-radius: 12px;
		padding-bottom: 72px;
	}

	.videos-tri-traveler-description {
		text-align: left;
		font-size: var(--txt-h5-xl);
		font-family: 'Bebas Neue', sans-serif;
	letter-spacing: 1px;
		bottom: 0;
		left: 5px;
		position: absolute;
	}

	.videos-tri-traveler-video-principal {
		border-radius: 12px;
		height: 100%;
		width: 100%;
	}

	.videos-tri-traveler-principal-description {
		font-size: var(--txt-h5-xl);
		font-weight: 700;
		font-family: 'Bebas Neue', sans-serif;
	letter-spacing: 1px;
	}
}
