.muertosAltar_container {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 81vh; /* Ajusta según prefieras */
    overflow: hidden;
  }

  .muertosAltar_container p {
    color: #000!important;
  }
  
  .muertosAltar_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }
  
  /* Botones con efecto de pulsación */
  .muertosAltar_pulseButton {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    cursor: pointer;
    animation: muertosAltar_pulse 1.5s infinite;
    z-index: 2;
  }
  
  @keyframes muertosAltar_pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Posiciones de los botones en la imagen */
  .muertosAltar_flores {
    top: 20%;
    left: 16%;
  }
  
  .muertosAltar_fotos {
    top: 78%;
    left: 39%;
  }
  
  .muertosAltar_cruz {
    top: 30%;
    left: 48%;
  }
  
  .muertosAltar_velas {
    top: 42%;
    left: 62%;
  }
  .muertosAltar_incienso {
    top: 43%;
    left: 54%;
  }
  .muertosAltar_pan {
    top: 53%;
    left: 48%;
  }
  
  .muertosAltar_lentes {
    top: 85%;
    left: 49%;
  }
  
  .muertosAltar_agua {
    top: 69%;
    left: 35%;
  }
  
  .muertosAltar_calaveras {
    top: 70%;
    left: 48%;
  }
  
  .muertosAltar_fruta {
    top: 70%;
    left: 65%;
  }
  
  .muertosAltar_papelPicado {
    top: 90%;
    left: 50%;
  }
  
  /* Caja de información que aparece al hacer clic */
  .muertosAltar_infoBox {
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 3;
    width: 200px;
  }
  
  .muertosAltar_infoBox h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .muertosAltar_infoBox p {
    color: black!important;
    text-align: left!important;
    font-size: 14px;
  }

  @media (max-width: 76px) {
    .muertosAltar_pulseButton {
      width: 20px;
      height: 20px;
    }
   
  }
  
  
  
  /* Resoluciones para pantallas pequeñas (móviles) */
  @media (min-width: 768px) and (max-width: 1014px) {
    .muertosAltar_pulseButton {
      width: 20px;
      height: 20px;
    }
  
   
  }
  
  

  @media (min-width: 1015px) and (max-width: 1024px) {
    .muertosAltar_pulseButton {
      width: 25px;
      height: 25px;
    }
  
   
  }
  
  /* Resoluciones para pantallas grandes */
  @media (min-width: 1025px) {
    .muertosAltar_pulseButton {
      width: 30px;
      height: 30px;
    }
  
   
  }