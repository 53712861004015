:root {
  --purple: #a6208c;
  --red: #e52619;
  --pink: #ec068b;
  --yellow: #fda700;
  --green: #88c243;
  --blue: #03a0af;
  --white: #e3e8ed;
  --grey: #cccccc;
  --gray: #a6a6a6;
  --black: #303030;
  --txt-title-xl: 62px;
  --txt-title-md: 52px;
  --txt-title-sm: 42px;
  --txt-p-sm: 16px;
  --txt-p-md: 18px;
  --txt-p-xl: 20px;
  --txt-h1-sm: 32px;
  --txt-h1-md: 34px;
  --txt-h1-xl: 36px;
  --txt-h2-sm: 28px;
  --txt-h2-md: 30px;
  --txt-h2-xl: 32px;
  --txt-h3-sm: 26px;
  --txt-h3-md: 28px;
  --txt-h3-xl: 30px;
  --txt-h4-sm: 24px;
  --txt-h4-md: 26px;
  --txt-h4-xl: 28px;
  --txt-h5-sm: 22px;
  --txt-h5-md: 24px;
  --txt-h5-xl: 26px;
  --txt-btn-sm: 14px;
  --txt-btn-md: 16px;
  --txt-btn-xl: 18px;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  overflow-x: hidden;
}

code {
  font-family: 'Roboto', sans-serif;
}
