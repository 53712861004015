/* Estilos para el modal */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0px;
    z-index: 1000;
    width: 80%; /* Ajusta el ancho del modal */
    max-width: 800px; /* Máximo ancho del modal */
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
  }
  
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  /* Estilos responsivos para el iframe */
  .video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
    height: 0;
    overflow: hidden;
    width: 100%;
    background: #000;
  }
  
  .video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Estilos adicionales para diferentes tamaños de pantalla */
  @media (min-width: 1024px) {
    .modal {
      width: 70%; /* Ajusta el ancho del modal para pantallas grandes */
      max-width: 1200px; /* Máximo ancho del modal */
    }
  }
  