.experience-intercative-ctn {
	display: block;
	padding: 50px 0 50px 0;
	background-image: url('../../assets/img/header/Fondoamarrillo.webp');
	background-repeat: no-repeat;
	background-size: 100% 50px;
	background-position: top;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.experience-intercative-title {
	font-size: var(--txt-title-sm);
	color: var(--yellow);
	font-family: 'Bebas Neue', sans-serif;
	letter-spacing: 1px;
	text-align: center;
}

.experience-intercative-ctn-buttons {
	display: flex;
	padding: 0 32px;
}

.experience-intercative-ctn-buttons-list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 24px;
}

.experience-intercative-buttons-btn {
	flex: 1 0 auto;
	height: 42px;
	background: var(--pink);
	color: #fff;
	font-weight: 400;
	font-size: var(--txt-h5-sm);
	border-radius: 7px;
	font-family: 'Bebas Neue', sans-serif;
  	letter-spacing: 3px;
	border:	none;
	cursor: pointer;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.experience-intercative-ctn-desciption {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	padding: 0 32px;
	box-sizing: border-box;
}

.experience-intercative-ctn-desciption-media {
	flex: 1 0 100%;
	box-sizing: border-box;
}

.experience-intercative-ctn-desciption-media-description {
	background: var(--grey);
	border-radius: 5px;
	padding: 12px 24px;
	height: auto;
	display: flex;
	justify-content: left;
	align-items: center;
	margin: 24px 0;
	width: auto;
}

.experience-intercative-desciption-media-description {
	font-size: var(--txt-h5-sm);
	text-align: center;
	line-height: 1.5;
}

.experience-intercative-ctn-desciption-media-images {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.experience-intercative-ctn-desciption-media-images-top {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	box-sizing: border-box;
	width: 100%;
}

.experience-intercative-ctn-desciption-media-image-top-link {
	flex: 1 0 100%;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
}

.experience-intercative-desciption-media-image-top-link {
	width: 100%;
	height: 100%;
	text-decoration: none;
	box-sizing: border-box;
}

.experience-intercative-desciption-media-image-top {
	width: 100%;
	height: 100%;
	object-fit: cover;
	aspect-ratio: 4/3;
	box-sizing: border-box;
}

.experience-intercative-ctn-desciption-media-images-top-hover-left {
	display: none;
}

.experience-intercative-ctn-desciption-media-images-top-hover-right {
	display: none;
}

.experience-intercative-ctn-desciption-media-image-top-link .experience-intercative-ctn-desciption-media-images-top-hover-left {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.experience-intercative-ctn-desciption-media-image-top-link:hover .experience-intercative-ctn-desciption-media-images-top-hover-left {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.experience-intercative-ctn-desciption-media-image-top-link:hover .experience-intercative-desciption-media-image-top {
	transform: scale(1.2);
  transition: 0.4s;
}

.experience-intercative-desciption-media-image-top-link-description {
	font-size: var(--txt-title-sm);
	color: #fff;
	font-family: 'Bebas Neue', sans-serif;
	letter-spacing: 1px;
	text-align: left;
	margin-left: 24px;
	margin-top: 20%;
	height: 100%;
	width: 70%;
}

.experience-intercative-ctn-desciption-media-images-bottom {
	margin-top: 24px;
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}


.experience-intercative-ctn-desciption-media-images-bottom-right {
	flex: 1 0 100%;
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}

@media screen and (min-width: 744px) {
	.experience-intercative-ctn {
		padding: 90px 0 90px 0;
		background-size: 100% 90px;
	}
	
	.experience-intercative-title {
		font-size: var(--txt-title-md);
	}
	
	.experience-intercative-ctn-buttons-list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		gap: 24px;
	}
	
	.experience-intercative-buttons-btn {
		font-size: var(--txt-h5-md);
	}
	
	.experience-intercative-desciption-media-description {
		font-size: var(--txt-h5-md);
		text-align: justify;
	}
	
	
	.experience-intercative-ctn-desciption-media-images-top {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		box-sizing: border-box;
		width: 100%;
	}
	
	.experience-intercative-ctn-desciption-media-image-top-link {
		flex: 1 0 40%;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}
	
	.experience-intercative-desciption-media-image-top-link {
		width: 100%;
		height: 100%;
		text-decoration: none;
		box-sizing: border-box;
	}
	
	.experience-intercative-desciption-media-image-top {
		width: 100%;
		height: 100%;
		object-fit: cover;
		aspect-ratio: 4/3;
		box-sizing: border-box;
	}
	
	.experience-intercative-ctn-desciption-media-image-top-link .experience-intercative-ctn-desciption-media-images-top-hover-left {
		display: none;
	}
	
	.experience-intercative-desciption-media-image-top-link-description {
		font-size: var(--txt-title-md);
		width: 80%;
	}
}

@media screen and (min-width: 1353px) {
	.experience-intercative-ctn {
		display: block;
		padding: 120px 0 90px 0;
		background-size: 100% 120px;
	}

	.experience-intercative-title {
		font-size: var(--txt-title-xl);
	}

	.experience-intercative-buttons-btn {
		font-size: var(--txt-h5-xl);
	}

	.experience-intercative-ctn-desciption-media {
		flex: 1 0 65%;
	}

	.experience-intercative-desciption-media-description {
		font-size: var(--txt-h5-xl);
	}

	.experience-intercative-ctn-desciption-media-image-top-link {
		flex: 1 0 40%;
		width: 100%;
		height: 570px;
	}

	.experience-intercative-desciption-media-image-top {
		height: 570px;
		object-fit: cover;
		aspect-ratio: auto;
	}


	.experience-intercative-desciption-media-image-top-link-description {
		font-size: var(--txt-title-xl);
	}

	.experience-intercative-ctn-desciption-media-images-bottom-right {
		flex: 1 0 40%;
		height: 570px;
	}
}
