.blog-articles-ctn {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.blog-articles-ctn-content {
  background: whitesmoke;
  padding: 0 7% 7% 7%;
}

.blog-articles-title {
  margin: 0;
  font-size: var(--txt-title-xl);
  color: var(--purple);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  /*text-shadow: 5px 5px 3px var(--grey);*/
}

.blog-articles-ctn-subtitle {
  display: flex;
  justify-content: space-between;
}

.blog-articles-subtitle {
  font-size: var(--txt-h5-xl);
  color: var(--gray);
}

.blog-articles-subtitle-button {
  text-decoration: none;
  color: #fff;
  padding: 12px;
  background: var(--green);
  width: 220px;
  height: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--txt-p-xl);
  margin-left: 150px;
}

.blog-articles-ctn-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  margin-top: 72px;
}

.blog-articles-ctn-card {
  flex: 1 0 30%;
  background: #fff;
}

.blog-articles-ctn-card-image {
  position: relative;
}

.blog-articles-card-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.blog-articles-card-date {
  background: var(--green);
  color: #fff;
  font-size: var(--txt-p-xl);
  position: absolute;
  bottom: 12px;
  left: 12px;
  padding: 7px;
  border-radius: 5px;
}

.blog-articles-ctn-card-info {
  padding: 12px 32px;
}

.blog-articles-card-info-date {
  font-size: var(--txt-p-xl);
  color: var(--gray);
}

.blog-articles-card-info-title {
  font-size: var(--txt-h5-sm);
  font-weight: 700;
  margin-bottom: 0;
  color: coral;
  text-align: center;
  margin: 24px auto;
}

.blog-articles-card-info-category {
  font-size: var(--txt-h5-sm);
  color: var(--gray);
  text-align: center;
  margin: 12px auto;
}

@media screen and (min-width: 744px) {
  .blog-articles-card-info-title {
    font-size: var(--txt-h5-md);
    text-align: left;
  }
  
  .blog-articles-card-info-category {
    font-size: var(--txt-h5-md);
    text-align: justify;
  }
}

@media screen and (min-width: 1353px) {
  .blog-articles-card-info-title {
    font-size: var(--txt-h5-xl);
    text-align: left;
  }
  
  .blog-articles-card-info-category {
    font-size: var(--txt-h5-xl);
    text-align: justify;
  }
}
