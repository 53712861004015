.home-sun-beach-and-sand-ctn {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.home-sun-beach-and-sand-ctn-card {
  width: 100%;
  height: 100%;
  position: relative;
}

.home-sun-beach-and-sand-ctn-card-img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.home-sun-beach-and-sand-card-img {
  width: 100%;
  min-height: 870px;
  aspect-ratio: auto;
  object-fit: cover;
}

.home-sun-beach-and-sand-ctn-card-description {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  min-height: 850px;
  background: #fff;
  border-radius: 10px;
  padding: 5%;
  box-shadow: 7px 5px 9px var(--black);
  text-overflow: ellipsis;
}

.home-sun-beach-and-sand-card-description-title {
  font-size: var(--txt-h2-xl);
  font-weight: 700;
  color: var(--yellow);
  border-bottom: 5px solid var(--yellow);
  width: auto;
  text-align: center;
  padding-bottom: 12px;
  margin-bottom: 62px;
}

.home-sun-beach-and-sand-card-description-subtitle {
  font-size: var(--txt-h5-sm);
  font-weight: 700;
  color: var(--gray);
  text-align: center;
  margin-bottom: 32px;
}

.home-sun-beach-and-sand-card-description-description {
  font-size: var(--txt-h5-sm);
  color: var(--gray);
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  line-height: 1.4;
}

.home-sun-beach-and-sand-card-description-button {
  text-decoration: none;
  color: #fff;
  background: var(--blue);
  font-size: var(--txt-p-sm);
  padding: 12px 18px;
  float: right;
  margin-top: 12px;
  border-radius: 6px;
}

@media screen and (min-width: 744px) {
  .home-sun-beach-and-sand-ctn {
    background: var(--blue);
    padding-top: 5%;
    background-image: url('../../assets/img/header/Fondoamarrillo.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
  
  .home-sun-beach-and-sand-ctn-card-description {
    top: 30%;
    right: 3%;
    width: 70%;
    min-height: 450px;
    padding: 35px;
  }
  
  .home-sun-beach-and-sand-card-description-title {
    padding-bottom: 24px;
  }
  
  .home-sun-beach-and-sand-card-description-subtitle {
    font-size: var(--txt-h5-xl);
  }
  
  .home-sun-beach-and-sand-card-description-description {
    font-size: var(--txt-h5-xl);
    text-align: justify;
  }
  
  .home-sun-beach-and-sand-card-description-button {
    font-size: var(--txt-p-xl);
    padding: 16px 24px;
  }
}

@media screen and (min-width: 1353px) {  
  .home-sun-beach-and-sand-ctn-card {
    width: 100%;
    height: 100%;
    position: relative;
    
  }
  
  .home-sun-beach-and-sand-card-img {
    width: 100%;
    height: 870px;
    aspect-ratio: auto;
    object-fit: cover;
  }
  
  .home-sun-beach-and-sand-ctn-card-description {
    top: 50%;
    min-height: 450px;
  }
  
  .home-sun-beach-and-sand-card-description-title {
    width: 420px;
  }
}
